<template>
  <div>
    <fieldsRows
        :templateContent="templateContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="{...value }"/>
  </div>
</template>
<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';
export default {
  components: {
    fieldsRows
  },
  props: ['field', 'fieldAttributes', 'result', 'value', 'valueLine'],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          pingLine: {
            type: 'line',
            fields: {
              command: {
                type: 'text',
                name: 'AT Command',
                colAttrs: {style: 'max-width:378px', class: ''},
                fieldAttrInput:{  } ,
              },
              waitTime: {
                type: 'number',
                name: 'Wait time',
                colAttrs: { cols: 1 },
                fieldAttrInput:{  } ,
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.result.contentID,
      };
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(index, value) {
      let localValue = {...this.value};
      if(index=='waitTime'){
        value = parseInt(value);
      }
      this.$set(localValue, index, value);
      this.$emit('input', localValue);
     }
  },
};
</script>